<template>
  <div>
    <ViewTemplateReportList :panel="panel" :reports="reports" />
  </div>
</template>

<script>
import ViewTemplateReportList from "@nixweb/nixloc-ui/src/component/template/ViewTemplateReportList";

export default {
  name: "ReportFinanceStockView",
  components: { ViewTemplateReportList },
  data() {
    return {
      panel: {
        module: "Financeiro",
        title: "Relatório",
        showFilter: false,
        showSearch: true,
        showButtons: false,
      },
      reports: {
        default: [
          {
            name: "Receita x Despesa de Locação",
            description: "Analise qual custo de cada locação...",
            routeName: "reportRentPerformance",
          },
          {
            name: "Pagar e Receber",
            description: "Filtre as contas por data de emissão, vencimento, tipo e outros critérios...",
            routeName: "reportPayableReceivable",
          },
        ],
        saved: [],
      },
    };
  },
};
</script>
